<!-- *************************** 我的订单 ****************************** -->
<template>
  <div class="preview">
    <h1 class="title">我的订单</h1>
    <div>
      <el-table :data="tableData" stripe style="width: 100%" border v-loading="loading">
        <el-table-column fixed prop="order_id" label="订单号" width="180"></el-table-column>
        <el-table-column prop="product_name" label="商品名称" width="130"></el-table-column>
        <el-table-column prop="payment_type" label="付款类型" width="130"></el-table-column>
        <el-table-column prop="total_fee" label="金额" width="70">
          <template slot-scope="scope">{{scope.row.total_fee/100}}</template>
        </el-table-column>
        <el-table-column prop="surcharge" label="手续费" width="70">
          <template slot-scope="scope">{{scope.row.surcharge/100}}</template>
        </el-table-column>
        <el-table-column prop="currency" label="货币" width="70"></el-table-column>
        <el-table-column prop="created_at" label="订单创建时间" width="180"></el-table-column>
        <el-table-column prop="paid_at" label="确认已支付时间" width="180"></el-table-column>
        <el-table-column prop="status" label="订单支付状态" width="110">
          <template slot-scope="scope">{{(scope.row.channel == 'Paypal') ? (scope.row.status != 1 ? statusStr[3] : statusStr[scope.row.status]) : (statusStr[scope.row.status])}}</template>
        </el-table-column>
        <el-table-column prop="status" label="是否去支付" width="110">
          <template slot-scope="scope">
            <a style="color:green;cursor:pointer" @click="()=>goPay(scope.row)">{{ scope.row.status === 0 && scope.row.channel != 'Paypal'? '去支付' : '' }}</a>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-sizes="[15,30, 60, 100]"
        :page-size="size"
        layout="sizes, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
    <!--*****************************  二维码弹框   **********************************-->
    <div class="mask" v-if="isShow">
      <div class="qr_box">
        <div class="qr_inner_box">
          <h1>{{channel=='Wechat'?'微信支付':'支付宝支付'}}</h1>
          <div id="paycode"></div>
          <div class="close">
            <i @click="closeMask" class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <!--*******  付款成功后提示弹框   ******** -->
      <div class="qr_box" v-if="status">
        <div class="inner_box">
          <i
            style="font-size:50px"
            :class="{'el-icon-circle-check':status==1,'el-icon-circle-close':status==2||status==3}"
          ></i>
          <p>{{statusStr[status]}}</p>
          <div class="close">
            <i @click="closeMask" class="el-icon-close"></i>
          </div>
        </div>
      </div>
    </div>
    <!--****************************  二维码弹框   ********************************** -->
  </div>
</template>
<script>
import {getMyOrderListApi} from "api/AdminCenter";
import {getOrderStatusApi} from "api/Recharge";
import QRcode from "qrcodejs2";

export default {
  data() {
    return {
      loading: true,
      current_page: 1,
      total: 1,
      size: 15,
      tableData: [], //列表
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单关闭"
      },
      status: "", //付款后返回状态
      isShow: false, //控制二维码弹框
      channel: "", //支付方式
      timer: null
    };
  },
  methods: {
    //去支付
    goPay(data) {
      const { code_url, channel, order_id } = data;
      this.channel = channel;
      this.isShow = true;
      setTimeout(() => {
        new QRcode("paycode", {
          text: code_url,
          width: 200, //图像宽度
          height: 200, //图像高度
          colorDark: "#517cfc",
          colorLight: "#ffffff",
          correctLevel: QRcode.CorrectLevel.H
        });
        this.timer = setInterval(() => this.getResult(order_id), 5000);
      }, 1000);
    },
    //查看支付订单状态
    getResult(order_id) {
      if (this.isShow) {
        getOrderStatusApi({ order_id }).then(res => {
          if (res.code == 0) {
            const { status } = res.data;
            this.getList({ size: this.size });
            //订单状态:0待支付/1支付成功/2支付失败/3订单关闭
            if (status != 0) {
              this.status = status;
              clearInterval(this.timer);
            }
          } else {
            clearInterval(this.timer);
            this.isShow = false;
          }
        });
      }
    },
    //关闭弹窗
    closeMask() {
      this.isShow = false;
    },
    //每页条数
    handleSizeChange(val) {
      this.getList({ size: val });
    },
    //当前第几页
    handleCurrentChange(val) {
      this.getList({ page: val, size: this.size });
    },
    //获取列表数据
    getList(values) {
      getMyOrderListApi(values).then(res => {
        if (res.code == 0) {
          this.loading = false;
          const { current_page, total, data } = res.data;
          data.map((item, index) => {
            item.index = index;
          });
          this.tableData = data;
          this.current_page = current_page;
          this.total = total;
        }
      });
    }
  },
  mounted() {
    this.getList({ size: this.size });
  }
};
</script>
<style lang="scss" scoped>
.preview {
  background: #fff;
  padding: 50px;
  .title {
    margin-bottom: 50px;
    font-size: 20px;
    color: #1a1a1a;
    text-align: left;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: center;
  }
  ::v-deep .el-table th {
    color: #fff;
    background: #4353b6;
  }
  .el-pagination {
    margin-top: 30px;
  }
  .mask {
    z-index: 100;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    .qr_box {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 430px;
      height: 430px;
      border-radius: 5px;
      .qr_inner_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-weight: 600;
          font-size: 28px;
          margin: 47px;
        }
      }
      .inner_box {
        position: relative;
        padding: 120px 0;
        p {
          font-size: 20px;
          margin-top: 20px;
        }
        i:first-chid {
          font-size: 50px;
        }
        i:nth-child(2) {
          font-size: 20px;
          color: #33333378;
        }
        .el-icon-circle-check {
          color: green;
        }
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        i {
          color: #33333378;
          font-size: 20px;
        }
      }
    }
  }
}
</style>